export function IsMovie(value: string): boolean {
  const videoExtensions = ['mp4', 'webm', 'ogg'];
  const extension = value.split('.').pop()?.toLowerCase();
  return videoExtensions.includes(extension || '');
}

export function IsBackgroundImage(value: string): boolean {
  const pictureExtensions = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];
  const extension = value.split('.').pop()?.toLowerCase();
  return pictureExtensions.includes(extension || '');
}