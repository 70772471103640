import Com from "../services/ComService";
import PropDTO, { IProp } from "./PropDTO";

export default class PropsDTO {
  props: PropDTO[] = []
  putTarget: string = ''
  parentId: number = -1
  propSource: string = ''
  constructor(data: IProps) {
    this.init(data)
  }

  public init(data: IProps) {
    this.propSource = data.propSource || this.propSource
    this.props = data.props ? data.props.map(p => new PropDTO(p, this.propSource)) : []
    this.putTarget = data.putTarget || this.putTarget
    this.parentId = data.parentId || this.parentId
  }

  getById(id: number) {
    return this.props.find(p => p.id === id)
  }

  getSingleByKeys(key1: string, key2: string) {
    return this.props.find(p => p.key1 === key1 && p.key2 === key2)
  }

  getPropVal1(key1: string, key2: string): string {
    const p = this.getSingleByKeys(key1, key2)
    return p?.value1 || ''
  }

  getPropFileBGString(key1: string, key2: string): string {
    const p = this.getSingleByKeys(key1, key2)
    if (!p || !p.file) {
      return ''
    }
    return p.file.getCSSString()
  }

  getPropFileMovieString(key1: string, key2: string): string {
    const p = this.getSingleByKeys(key1, key2)
    if (!p || !p.file) {
      return ''
    }
    return p.file.getMovieString()
  }

  async setPropVal(data: {
    key1: string,
    key2: string,
    value1: string,
    value2?: string,
    fileId?: number,
  }) {
    const prop = this.getSingleByKeys(data.key1, data.key2)
    if (prop) {
      const newItem = await Com(`PATCH:${this.putTarget}`, {
        ...data, ...{
          id: prop.id,
          value1: data.value1,
          value2: data.value2 || prop.value2,
          fileId: data.fileId || -1
        }
      }, 3)
      this.props = this.props.filter(p => p.id !== prop.id)
      this.props.push(new PropDTO(newItem as IProp))
    } else {
      this.put({...data, ...{
        value2: data.value2 || '',
        fileId: data.fileId || -1
      }})
    }
  }

  async removeProp(key1: string, key2: string) {
    const prop = this.props.find(p => (p.key1 === key1 && p.key2))
    await prop?.delete()
    this.props = this.props.filter(p => !(p.key1 === key1 && p.key2))
  }

  async put(data: {key1: string, key2: string, value1: string, value2: string, fileId?: number}) {
    const newItem = await Com(`PUT:${this.putTarget}`, {
      ...data, ...{
        parentId: this.parentId,
        fileId: data.fileId || -1
      }
    }, 3)
    this.props.push(new PropDTO(newItem as IProp, this.propSource))
  }
}

interface IProps {
  props?: IProp[],
  propSource?: string,
  putTarget?: string,
  parentId?: number,
}