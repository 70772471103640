import { useEffect, useState } from "react"
import { ScreenDTO } from "../../dto/ScreenDTO"
import { SnippedDTO, SnippedKind } from "../../dto/SnippedDTO"
import Com from "../../services/ComService"
import { Snipped } from "../Snipped/Snipped"
import './Screen.scss'

enum Status {
  loading,
  done,
}

export enum CanvasStatus {
  load,
  ready,
  show,
  dontChange,
  hide,
}

const Order = [
  {
    AState: CanvasStatus.load,
    BState: CanvasStatus.show,
  },
  {
    AState: CanvasStatus.ready,
    BState: CanvasStatus.show,
  },
  {
    AState: CanvasStatus.show,
    BState: CanvasStatus.hide,
  },
  {
    AState: CanvasStatus.show,
    BState: CanvasStatus.load,
  },
  {
    AState: CanvasStatus.show,
    BState: CanvasStatus.ready,
  },
  {
    AState: CanvasStatus.hide,
    BState: CanvasStatus.show,
  },
]

let snippeds: SnippedDTO[]
let orderSwitchStamp = -1
let snippedPointer = -1
let Ticker = 0
let orderPointer = 0
let version = ''
let snippedAId = -1
let snippedBId = -1

/**
 * Increments any pointer  for a given array
 * @param arr the array the pointer is pointing to
 * @param current current pointer value
 * @returns new pointer value
 */
function incrementPointer(arr: any[], current: number): number {
  const newPointer = current + 1
  if (arr[newPointer]) {
    return newPointer
  }
  return 0
}

var lastCheck = 0

export function Screen(props: {}) {
  const MAXRATE = 30000
  const hash = (window.location.href.match(/\/screen\/([a-z0-9]+)/) || ['', ''])[1]
  const [item] = useState(new ScreenDTO({hash: hash}))
  const [status, setStatus] = useState(Status.done)
  const [Rerender, setRerender] = useState(0)
  const [lorderPointer, setLorderPointer] = useState(0)
  const [snippedPointerA, setSnippedPointerA] = useState(-1)
  const [snippedPointerB, setSnippedPointerB] = useState(-1)
  function setOrderPointer(o: number) {
    orderPointer = o
    setLorderPointer(o)
  }

  function reload(cache: 'true' | 'set') {
    item.getFromServer(cache).then(u => {
      rearm()
      setStatus(Status.done)
      setRerender(Rerender + 1)
      // orderPointer = 1
    })
  }

  function rearm() {
    snippeds = item.getSnippeds()
    // Ask Server, if anything changed!
    const currentStamp = new Date().getTime()
    // Only ask once per minute - maximum!
    if (lastCheck > currentStamp - MAXRATE) {
      return
    }
    lastCheck = currentStamp
    Com(`GET:screen/${hash}/version`, {}, 3).then((info) => {
      console.log('info', info)
      if ((info as {version: string}).version !== version) {
        version = (info as {version: string}).version
        reload('set')
      }
    })
  }

  function detectLonelySnipped() {
    console.log('>>>', snippeds.length)
    return (snippeds.length === 1 && snippedPointer === 0 && snippedAId > -1 && snippedBId > -1 && snippedAId === snippedBId)
    // return false
  }

  function tick() {
    if (status === Status.loading) { return }
    const currentOrder = Order[orderPointer]

    // Case LOAD
    if (currentOrder.AState === CanvasStatus.load || currentOrder.BState === CanvasStatus.load) {
      let sp = incrementPointer(snippeds, snippedPointer)
      if (sp <= snippedPointer) { rearm() }
      if (currentOrder.AState === CanvasStatus.load) {
        setSnippedPointerA(sp)
      }
      if (currentOrder.BState === CanvasStatus.load) {
        setSnippedPointerB(sp)
      }
      // console.log('>>>', snippeds[snippedPointerA]?.id , snippeds[snippedPointerB]?.id, snippeds)
      // console.log('>>>', snippedPointerA, snippedPointerB, snippeds[snippedPointerA] , snippeds[snippedPointerB] , snippeds[snippedPointerA]?.id , snippeds[snippedPointerB]?.id)
      if (detectLonelySnipped()) {
      // if (snippedPointerA === snippedPointerB) {
        // If the snipped is the same we do not want to change
        return
      }
      setOrderPointer(incrementPointer(Order, orderPointer))
      return
    }

    // Case HIDE
    if (currentOrder.AState === CanvasStatus.hide || currentOrder.BState === CanvasStatus.hide) {
      setOrderPointer(incrementPointer(Order, orderPointer))
      return
    }

    // Case READY
    if (!(currentOrder.AState === CanvasStatus.ready || currentOrder.BState === CanvasStatus.ready)) {
      return
    }
    // Case ChangeStamp reached
    const now = new Date().getTime()
    if (orderSwitchStamp > now) { return }
    snippedPointer = incrementPointer(snippeds, snippedPointer)
    let snippedDisplayDuration = 10
    if (snippeds && snippeds[snippedPointer]) {
      snippedDisplayDuration = snippeds[snippedPointer].getDisplayDuration()
    }
    orderSwitchStamp = now + snippedDisplayDuration
    setOrderPointer(incrementPointer(Order, orderPointer))

    return
  }
  function getContentKindClass(snippeds: SnippedDTO[], index: number) {
    return (index === -1 || !snippeds[index] || snippeds[index].kind === SnippedKind.HTML) ? ' normal-content ' : ' special-content '
  }
  useEffect(() => {
    reload('set')
    clearInterval(Ticker)
    Ticker = window.setInterval(() => {
      tick()
    }, item.getTickInterval())
  }, [])
  snippedAId = (snippedPointerA > -1 && snippeds[snippedPointerA]) ? snippeds[snippedPointerA].id : -1
  snippedBId = (snippedPointerB > -1 && snippeds[snippedPointerB]) ? snippeds[snippedPointerB].id : -1

  return <div className={`screenHolder transitionKind-${item.transitionKind}`}>
    <div className={'CanvasA' + getContentKindClass(snippeds, snippedPointerA) + classFromState(Order[lorderPointer].AState)}>
      { snippedPointerA > -1 && snippeds[snippedPointerA] &&
        <Snipped
          item={snippeds[snippedPointerA]}
          sendDoneSignal={() => {
            orderSwitchStamp = new Date().getTime()
          }}
          CanvasStatus={Order[lorderPointer].AState}
          tickInterval={item.getTickInterval()}
        />
      }
    </div>
    <div className={'CanvasB' + getContentKindClass(snippeds, snippedPointerB) + classFromState(Order[lorderPointer].BState)}>
      { snippedPointerB > -1 && snippeds[snippedPointerB] &&
        <Snipped
          item={snippeds[snippedPointerB]}
          sendDoneSignal={() => {
            orderSwitchStamp = new Date().getTime()
          }}
          CanvasStatus={Order[lorderPointer].BState}
          tickInterval={item.getTickInterval()}
        />
      }
    </div>
  </div>
}

function classFromState (state: CanvasStatus) {
  let className = 'screen-canvas '
    switch(state) {
      case CanvasStatus.load:
        className += ' canvas-load'
        break
      case CanvasStatus.ready:
        className += ' canvas-ready'
        break
      case CanvasStatus.show:
        className += ' canvas-show'
        break
      case CanvasStatus.hide:
        className += ' canvas-hide'
        break
    }
    return className
}
