import { useEffect, useState } from "react";
import { X } from "react-feather";
import ScheduleAdminDTO from "../../../dto/ScheduleAdminDTO";
import TimeSelector from "../../Input/TimeSelector";
import DateSelector from "../../Input/DateSelector";
import DaySelector from "../../Input/DaySelector";
import TimeMatrixSelect from "./TimeMatrixSelect";
import { getGroupIdFromUrl } from "../Group"
import { useNavigate } from "react-router-dom";
import { SelectPriority } from "./SelectPriority";
import { SlotAdminDTO } from "../../../dto/SlotAdminDTO";
import { Status } from "../Status";
import ListItem from "../ListItem";
import { QuickFileUpload } from "../Slot/QuickFileUpload";

export default function Schedule (props: {
  schedule: ScheduleAdminDTO
  close?: () => void,
  cb?: () => void,
}) {
  
  const schedule = props.schedule
  const [slot] = useState(new SlotAdminDTO(schedule.slot))
  const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(Status.loading)
  const navigate = useNavigate();
  function reload(cache: 'true' | 'set') {
    setRerender(rerender + 1)
  }
  useEffect(() => {
    slot.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  console.log('SCHEDULE', schedule.weekdays)
  if (schedule.weekdays.search(/[,oirs]/) > -1) {
    // Legacy Mode
    return <div className={`w3-card w3-margin-top w3-padding w3-row`}>
      
      <div
        className={`schedule-slot-holder w3-button w3-col s12 slot-${slot.id} flex`}
        onClick={() => {
          navigate(`/admin/group/${getGroupIdFromUrl()}/snipped/${slot.id}`)
        }}
      >
        {
          schedule.slot.name
        }
      </div>
      
      <details>
        <summary>
          <span className="w3-small">Expand/Collapse</span>
        </summary>

        <DaySelector
          days={schedule.weekdays}
          newDays={async (newDays) => {
            await schedule.setWeekdays(newDays)
            setRerender(rerender + 1)
            if (props.cb) { props.cb() }
          }}
        />
        <TimeSelector
          title={'Tägliche Startzeit'}
          currentTime={schedule.startTime}
          newTime={async (newTime) => {
            await schedule.setStartTime(newTime)
            setRerender(rerender + 1)
            if (props.cb) { props.cb() }
          }}
          fallbackTime='00:00'
        />
        <TimeSelector
          title={'Tägliche Endzeit'}
          currentTime={schedule.endTime}
          newTime={async (newTime) => {
            await schedule.setEndTime(newTime)
            setRerender(rerender + 1)
            if (props.cb) { props.cb() }
          }}
          fallbackTime='24:00'
        />
        <DateSelector
          title='Start Datum'
          value={schedule.start}
          newValue={(val) => {
            schedule.setStart(val)
            setRerender(rerender + 1)
          }}
        />
        <DateSelector
          title='End Datum'
          value={schedule.end}
          newValue={(val) => {
            schedule.setEnd(val)
            setRerender(rerender + 1)
          }}
          mode='evening'
        />
        <SelectPriority schedule={schedule} />
      </details>
    </div>
  }
  // Standard Version:
  return <div className={`w3-border`}>
    <div className='flex'>
      <div
        className={`flex-grow slot-${slot.id} w3-button`}
        onClick={() => {
          navigate(`/admin/group/${getGroupIdFromUrl()}/slot/${slot.id}`)
        }}
      >
        {
          schedule.slot.name
        }
      </div>
      <button
        className='w3-button'
        title='Schließen'
        onClick={() => {
          if (props.close) { props.close() }
        }}
      ><X /></button>
    </div>
    <div className='w3-row'>
      <TimeMatrixSelect
        externalId={schedule.id}
        timeString={schedule.weekdays}
        change={async (newDays) => {
          console.log('setNewDays', newDays)
          await schedule.setWeekdays(newDays)
            // setRerender(rerender + 1)
            // if (props.cb) { props.cb() }
        }}
        className='w3-col s12 m12 l6 w3-padding'
      />
      <DateSelector
        title='Start Datum'
        value={schedule.start}
        newValue={(val) => {
          schedule.setStart(val)
          setRerender(rerender + 1)
        }}
        className={'w3-col s12 m12 l6 w3-padding'}
      />
      <DateSelector
        title='End Datum'
        value={schedule.end}
        newValue={(val) => {
          schedule.setEnd(val)
          setRerender(rerender + 1)
        }}
        mode='evening'
        className={'w3-col s12 m12 l6 w3-padding'}
      />
      <SelectPriority
        schedule={schedule}
        className={'w3-col s12 m12 l6 w3-padding'}
      />
    </div>

    <div className=''>
      <QuickFileUpload
        slot={slot}
        cb={() => {
          console.log('upload done')
          reload('set')
        }}
      />
      {
        slot.getSnippeds().map((s, index) => 
          <ListItem
            key={`slot-${slot.id}-snipped-${s.id}-index-${index}`}
            item={s}
            index={index}
            reload={() => {
              reload('set')
            }}
            open={() => {
              navigate(`/admin/group/${getGroupIdFromUrl()}/snipped/${s.id}`)
            }}
            remove={async () => {
              await slot.removeSnipped(s.id)
              reload('set')
            }}
            kind='snipped'
            dragging={false}
            up={async () => {
              setStatus(Status.loading)
              await slot.moveSnipped(s.id, index - 1, index)
              setStatus(Status.done)
            }}
            down={async () => {
              setStatus(Status.loading)
              await slot.moveSnipped(s.id, index + 1, index)
              setStatus(Status.done)
            }}
            snippeds={[s]}
          />
        )
      }
    </div>
  </div>
}

