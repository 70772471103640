export function RemoveDoublicates(arr: {id: number}[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a.id) > -1) { return false }
    ids.push(a.id)
    return true
  })
}

export function UniqueIds(arr: number[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a) > -1) { return false }
    ids.push(a)
    return true
  })
}

// Old disposable code:
export function SortBySortorderLegacy(arr: {id: number}[], sortOrder: string): any[] {
  const sortIds = sortOrder.split(';').map(id => parseInt(id, 10))
  const sortedArr = arr.sort((a, b) => {
    const indexA = sortIds.indexOf(a.id);
    const indexB = sortIds.indexOf(b.id);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return sortedArr;

}

export function SortBySortorder(arr: {id: number}[], sortOrder: string): any[] {
  const sortIds = sortOrder.split(';').map(id => parseInt(id, 10))
  let out: any[] = []
  // Sort in the order of the sort IDs:
  sortIds.forEach(sid => {
    const item = arr.find(i => i.id === sid)
    if (item) {
      out.push(item)
    }
  })
  // Append all itmes, which has not been used yet
  arr.forEach(item => {
    if (!out.find(i => i.id === item.id)) {
      out.push(item)
    }
  })
  return out;
}

export function AddItemToSortorder(arr: {id: number}[], sortOrder: string, item: {id: number}, position: number): string {
  const sorted = SortBySortorder(arr, sortOrder)
  const added = [...sorted.slice(0, position), item, ...sorted.slice(position)]
  return added.map(i => i.id).join(';')
}

export function MoveItemToPosition(arr: {id: number}[], sortOrder: string, item: {id: number}, newPosition: number, oldPosition: number): string {
  const sorted = SortBySortorder(arr, sortOrder)
  const correctedArray = [...sorted.slice(0, oldPosition), ...sorted.slice(oldPosition + 1)]
  correctedArray.splice(newPosition, 0, item)
  return correctedArray.map(i => i.id).join(';')
}
