import { UniqueIds } from "../services/ArrayService"
import Com from "../services/ComService"
import { IProp } from "./PropDTO"
import { ISchedule } from "./ScheduleDTO"
import { SchedulesDTO } from "./SchedulesDTO"
import { SlotDTO } from "./SlotDTO"
import { SnippedDTO } from "./SnippedDTO"

export class ScreenDTO {
  hash: string
  
  sourceUrl = 'screen'
  schedules = new SchedulesDTO()
  slots: SlotDTO[] = []
  snippeds: SnippedDTO[] = []
  transitionKind: string = 'slide'

  constructor(data: IScreen) {
    this.hash = data.hash
  }
  private init (data: IData) {
    this.schedules.setItems(data.screen.schedules)
    this.slots = data.slots.map(s => new SlotDTO(s))
    this.snippeds = data.snippeds.map(s => new SnippedDTO(s))
    this.transitionKind = data.screen.props.find(p => p.key1 === 'transition' && p.key2 === 'kind')?.value1 || 'slide'
  }

  public async getFromServer(cache?: string) {
    if (!this.hash) { return }
    const cacheString = cache || 'true'
    const data = await Com(`GET:${cacheString}:${this.sourceUrl}/${this.hash}`, {}, 3) as IData
    this.init(data)
  }

  /**
   * Get all Snippeds
   */
  public getSnippeds(): SnippedDTO[] {
    let snippedIds: number[] = []
    const schedules = this.schedules.getCurrent()
    const slotIds = UniqueIds(schedules.map(s => s.slotId))
    slotIds.forEach(s => {
      const slot = this.slots.find(sl => sl.id === s)
      if (!slot) { return }
      snippedIds = snippedIds.concat(slot.snippedIds)
    })
    return snippedIds.map(s => {
      return this.snippeds.find(sn => sn.id === s)
    }) as SnippedDTO[]
  }

  public getTickInterval() {
    return this.transitionKind !== 'instant' ? 3000 : 500
  }
}

export interface IScreen {
  hash: string
}

export interface IData {
  screen: {
    schedules: ISchedule[],
    props: IProp[],
  },
  slots: any[],
  snippeds: any[],
}
