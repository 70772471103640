import { SlotAdminDTO } from "../../../dto/SlotAdminDTO"
import { useEffect, useState } from "react"
import { getGroupIdFromUrl } from "../Group"
import { useNavigate } from "react-router-dom"
import ListItem from "../ListItem"
import Spinner from "../../Spinner/Spinner"
import { Snippeds } from "../Snippeds"
import '../Admin.scss'
import { DropZone } from "../DropZone"
import { QuickFileUpload } from "./QuickFileUpload"

enum Status {
  loading,
  rename,
  done,
}

type DropItem = {
  id: number,
  kind: string,
}

export function getAdminSlotIdFromUrl(): number {
  const raw = window.location.href.match(/group\/([0-9]+)\/slot\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[2], 10)
}

export function Slot(props: {
  id?: number,
  reload?: () => void
}) {
  const id = props.id || getAdminSlotIdFromUrl()
  const [item] = useState(new SlotAdminDTO({id: id}))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [currentId, setCurrentId] = useState(id)
  const navigate = useNavigate();
  
  
  function reload(cache: 'true' | 'set') {
    item.setId(id)
    setCurrentId(id)
    setStatus(Status.loading)
    item.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
    
  }
  if (currentId !== id) {
    reload('set')
    
  }
  useEffect(() => {
    // reload('set')
    item.getFromServer().then(() => {
      console.log('Screen Info 2', item)
      setStatus(Status.done)
    })
  }, [])

  if (status === Status.loading) {
    return <div className={`flexGrow flexRows Screen ${Rerender}`}>
      Lade Slot
      <Spinner />
    </div>
  }
  return <div className={`slot flexRows ${Rerender}`}>
    <div className='single-top'>
      <h2>Seite: {item.name}</h2>
    </div>
    <div className='flexColumns flexGrow w3-row'>
      <div className='usedsnippedList h100 flexRows w3-col s12 m6 l6 w3-border-right'>
        <h2 className='h2Admin'>Genutzte Schnippsel</h2>
        <div className='flexGrow admin-scroll-panel'>
          <QuickFileUpload
            slot={item}
            cb={() => {
              console.log('upload done')
              setRerender(Rerender + 1)
            }}
          />
          <DropZone
            label='hinzufügen'
            position={0}
            addItem={async (position, droppedItem) => {
              console.log('position, item', position, droppedItem)
              item.addSnipped(droppedItem.id, 0).then(() => reload('set'))
            }}
            acceptKind="listItem"
          />
          {
            item.getSnippeds().map((s, index) => 
              <div
                key={`slot-${item.id}-snipped-${s.id}-${index}`}
              >
                <ListItem
                  item={s}
                  index={index}
                  reload={() => {
                    reload('set')
                  }}
                  open={() => {
                    navigate(`/admin/group/${getGroupIdFromUrl()}/snipped/${s.id}`)
                  }}
                  remove={async () => {
                    await item.removeSnipped(s.id)
                    reload('set')
                  }}
                  kind='snipped'
                  dragging={false}
                  up={async () => {
                    setStatus(Status.loading)
                    await item.moveSnipped(s.id, index - 1, index)
                    setStatus(Status.done)
                  }}
                  down={async () => {
                    setStatus(Status.loading)
                    await item.moveSnipped(s.id, index + 1, index)
                    setStatus(Status.done)
                  }}
                  snippeds={[s]}
                />
                <DropZone
                  label='hinzufügen'
                  position={index}
                  addItem={async (position, droppedItem) => {
                    console.log('position, item', position, droppedItem)
                    item.addSnipped(droppedItem.id, position + 1).then(() => reload('set'))
                  }}
                  acceptKind="listItem"
                />
            </div>
          )
          }
        </div>
      </div>
      <div className='flexGrow flexRows'>
        <h2 className='h2Admin'>Verfügbare Schnippsel</h2>
        <Snippeds
          className=''
        />
      </div>
    </div>
  </div>
}
