import { useState } from "react";
import ScheduleAdminDTO from "../../../dto/ScheduleAdminDTO";


export function SelectPriority(props: {
  schedule: ScheduleAdminDTO,
  className?: string,
}) {
  const [value, setValue] = useState(props.schedule.priority);
  const Priorities = [
    {
      value: 1,
      name: 'Füller',
      color: '#aaa',
      fgColor: '#000',
    },
    {
      value: 10,
      name: 'Normal',
      color: '#4CAF50',
      fgColor: '#fff',
    },
    {
      value: 50,
      name: 'Überschreiber',
      color: 'red',
      fgColor: '#fff',
    },
    {
      value: 100,
      name: 'TOP',
      color: 'purple',
      fgColor: '#fff',
    },
  ];
  return <div className={`SelectPriority ${props.className || ''}`}>
    <div
      title="Eine höere Priorität bewirkt, dass zu einem Zeitpunkt nur Dinge mit dieser Priorität angezeigt werden. Die höchste Priorität gewinnt!"
    >Priorität</div>
    {Priorities.map((p, index) => {
      const active = p.value === value;
      return <button
        key={`priority-${index}`}
        className={``}
        style={{
          backgroundColor: active ? p.color : '#fff',
          color: active ? p.fgColor : '#000',
          border: '1px solid ' + p.color,
          cursor: 'pointer',
          margin: '4px',
        }}
        title={p.name}
        onClick={async () => {
          await props.schedule.setPriority(p.value);
          setValue(p.value);
        }}
      >{p.name}</button>;
    })}
  </div>;
}
